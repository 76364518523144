import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import constants from "@/constants.js"
import utils from "@/utils.js"
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const moment = require('moment')

let instance = null

export default class ExportPdf {
    constructor() {
        if (instance) {
            return instance
        }

        instance = this

        // fonts

        pdfMake.fonts = {
            raleway: {
                normal:
                    "https://manager.lifereport.tv/assets/fonts/Raleway-Regular.ttf",
                bold: "https://manager.lifereport.tv/assets/fonts/Raleway-Bold.ttf",
            },
            ralewayMedium: {
                normal:
                    "https://manager.lifereport.tv/assets/fonts/Raleway-Medium.ttf",
            },
            ralewaySemiBold: {
                normal:
                    "https://manager.lifereport.tv/assets/fonts/Raleway-SemiBold.ttf",
            },
            ralewayExtraBold: {
                normal:
                    "https://manager.lifereport.tv/assets/fonts/Raleway-ExtraBold.ttf",
            },
        };

        // constants
        this.headerPaddingTop = 25

        this.padding = 5;
        this.tablePadding = 6
        this.commentWidth = 140
        this.paddingOverviewTop = 40;
        this.paddingOverview = 15;
        this.colorOrange = "#f8b10c";
        this.colorGrey = "#a4a4a4";
        this.colorWhite = "white";
        this.tableWidths = ["*", 40, 30, "*"];
        this.tableWidthsPricelist = ["*", 40, 30, 25, 30, this.commentWidth];
        this.iconWidth = 15;
        this.margin = 40

        // layout

        this.layoutTable = {
            hLineWidth: (i, node) => {
                if (i == 0 || i == node.table.body.length) return 0;
                return i === node.table.headerRows ? 1 : 0.2;
            },
            vLineWidth: () => {
                return 0;
            },
            hLineColor: (i, node) => {
                return i === node.table.headerRows ? this.colorOrange : this.colorGrey;
            },
            paddingLeft: () => {
                return 0
            },
            paddingRight: () => {
                return 0
            },
            paddingTop: (i) => {
                if (i == 0) return this.padding * 2
                if (i == 1) return this.tablePadding
                return this.tablePadding / 2;
            },
            paddingBottom: (i) => {
                if (i == 0) return this.padding
                return this.tablePadding / 2
            },
        };

        this.layoutInnerTable = {
            hLineWidth: () => {
                return 0
            },
            vLineWidth: () => {
                return 0;
            },
            paddingLeft: (i) => {
                if (i == 0) return this.padding / 2;
                return this.padding * 2
            },
            paddingRight: (i, node) => {
                if (i == node.table.widths.length - 1) return this.padding / 2;
                return this.padding * 2
            },
            paddingTop: () => {
                return 0
            },
            paddingBottom: () => {
                return 0
            },
        };

        this.layoutHeaderMain = {
            hLineWidth: () => {
                return 0;
            },
            vLineWidth: () => {
                return 0;
            },
            paddingTop: (i) => {
                if (i == 0) {
                    return this.headerPaddingTop;
                }
                return 3
            },
            paddingBottom: () => {
                return this.padding;
            },
        };

        this.layoutHeaderSub = {
            hLineWidth: () => {
                return 0;
            },
            vLineWidth: () => {
                return 0;
            },
            paddingTop: () => {
                return this.padding;
            },
            paddingBottom: () => {
                return this.padding;
            },
        };

        this.layoutHeaderSubPadding = {
            hLineWidth: () => {
                return 0;
            },
            vLineWidth: () => {
                return 0;
            },
            paddingTop: () => {
                return this.padding * 4;
            },
            paddingBottom: () => {
                return this.padding;
            },
        };

        this.layoutOverview = {
            hLineWidth: () => {
                return 0;
            },
            vLineWidth: () => {
                return 0;
            },
            paddingLeft: (i) => {
                if (i == 0) return this.paddingOverview
                return this.padding * 2
            },
            paddingRight: (i, node) => {
                if (i == node.table.widths.length - 1) return this.paddingOverview;
                return this.padding * 2
            },
            paddingTop: () => {
                return this.paddingOverview + this.paddingOverviewTop;
            },
            paddingBottom: () => {
                return this.paddingOverview;
            },
        };

        // style
        this.style = {
            headingTable: {
                font: "ralewayMedium",
                fontSize: 8,
                characterSpacing: 0.2,
            },
            headingTitle: {
                font: "ralewaySemiBold",
                fontSize: 9.3,
                alignment: "center",
                characterSpacing: 0.2,
            },
            headingMain: {
                color: this.colorWhite,
                font: "ralewaySemiBold",
                fontSize: 9.3,
                alignment: "center",
                characterSpacing: 0.2,
            },
            headingSub: {
                color: this.colorOrange,
                font: "ralewayExtraBold",
                fontSize: 9.3,
                alignment: "center",
                characterSpacing: 0.2,
            },
            rowFee: {
                font: "ralewayMedium",
                fontSize: 8,
                characterSpacing: 0.2,
            },
            contact: {
                font: "ralewaySemiBold",
                fontSize: 9.3,
                characterSpacing: 0.2,
            },
        };

        this.defaultStyle = {
            font: "raleway",
            fontSize: 8,
            characterSpacing: 0,
        }

        this.images = {
            logo: "https://manager.lifereport.tv/assets/images/logo.png",
            address:
                "https://manager.lifereport.tv/assets/images/icon_address.png",
            mail: "https://manager.lifereport.tv/assets/images/icon_mail.png",
            phone: "https://manager.lifereport.tv/assets/images/icon_phone.png",
        }
    }

    createHeader() {
        return [{
            layout: "noBorders",
            table: {
                widths: ["auto", "*", "auto"],
                body: [
                    [
                        {
                            image: "logo",
                            width: 150,
                        },
                        {},
                        {
                            layout: "noBorders",
                            table: {
                                width: ["auto", "auto"],
                                body: [
                                    [
                                        { image: "phone", width: this.iconWidth },
                                        { text: "+420 777 205 872", style: "contact" },
                                    ],
                                    [" ", {
                                        canvas: [
                                            {
                                                type: "rect",
                                                x: 0,
                                                y: 0,
                                                w: 18,
                                                h: 1,
                                                r: 0,
                                                color: this.colorOrange,
                                            },
                                        ],
                                        relativePosition: { x: 0, y: 0 },
                                    }],
                                    [
                                        { image: "mail", width: this.iconWidth },
                                        { text: "life@lifereport.tv", style: "contact" },
                                    ],
                                    [" ", {
                                        canvas: [
                                            {
                                                type: "rect",
                                                x: 0,
                                                y: 0,
                                                w: 18,
                                                h: 1,
                                                r: 0,
                                                color: this.colorOrange,
                                            },
                                        ],
                                        relativePosition: { x: 0, y: 0 },
                                    }],
                                    [
                                        { image: "address", rowSpan: 3, width: this.iconWidth },
                                        { text: "Družstevní 1395/8", style: "contact" },
                                    ],
                                    ["", { text: "Praha 4 - Nusle", style: "contact" }],
                                    ["", { text: "140 00", style: "contact" }],
                                ],
                            },
                        },
                    ],
                ],
            },
        },]
    }

    createTable(pricelistitems, pricelist, title, currencyText, rate) {
        let content = []

        const mainCategories = Array.from(
            new Set(pricelistitems.map((item) => item.categoryMain))
        )

        const categories = []

        for (const constantCategory of constants.pricelistMainCategories) {
            for (const category of mainCategories) {
                if (constantCategory == category) {
                    const subCategories = Array.from(
                        new Set(pricelistitems.filter((item) => item.categoryMain == category).map((item) => item.categorySub))
                    )

                    const sub = []

                    for (const constantSubCategory of constants.pricelistSubCategoriesServices.concat(constants.pricelistSubCategoriesGear)) {
                        for (const categorySub of subCategories) {
                            if (constantSubCategory == categorySub) {
                                sub.push(categorySub)
                            }
                        }
                    }

                    categories.push({
                        main: category,
                        sub: sub
                    })
                }
            }
        }

        for (const [i, category] of categories.entries()) {
            // main category
            if (i == 0 && title) {
                content.push({
                    canvas: [
                        {
                            type: "rect",
                            x: 0,
                            y: this.headerPaddingTop / 2 + 8.5,
                            w: 516,
                            h: 19,
                            r: 3,
                            lineColor: this.colorOrange,
                        },
                        {
                            type: "rect",
                            x: -5,
                            y: this.headerPaddingTop / 2 + 8.5 + 10,
                            w: 526,
                            h: 9,
                            lineColor: "white",
                            color: "white",
                        },
                        {
                            type: 'line',
                            x1: 0, y1: this.headerPaddingTop / 2 + 8.5 + 5,
                            x2: 0, y2: this.headerPaddingTop / 2 + 8.5 + 24,
                            lineWidth: 1,
                            lineColor: this.colorOrange,
                        },
                        {
                            type: 'line',
                            x1: 516, y1: this.headerPaddingTop / 2 + 8.5 + 5,
                            x2: 516, y2: this.headerPaddingTop / 2 + 8.5 + 24,
                            lineWidth: 1,
                            lineColor: this.colorOrange,
                        },
                        {
                            type: "rect",
                            x: 0,
                            y: this.headerPaddingTop / 2 + 8.5 + 19,
                            w: 516,
                            h: 19,
                            r: 3,
                            color: this.colorOrange,
                            lineColor: this.colorOrange,
                        },
                        {
                            type: "rect",
                            x: 0,
                            y: this.headerPaddingTop / 2 + 8.5 + 19,
                            w: 516,
                            h: 10,
                            color: this.colorOrange,
                            lineColor: this.colorOrange,
                        },
                    ],
                    relativePosition: { x: 0, y: 0 },
                })

                content.push({
                    headlineLevel: 1,
                    table: {
                        widths: ["*"],
                        body: [
                            [
                                {
                                    text: title.toUpperCase(),
                                    style: "headingTitle",
                                },

                            ],
                            [
                                {
                                    text: category.main.toUpperCase(),
                                    style: "headingMain",
                                }
                            ]
                        ],
                    },
                    layout: this.layoutHeaderMain,
                })
            } else {
                content.push({
                    canvas: [
                        {
                            type: "rect",
                            x: 0,
                            y: this.headerPaddingTop / 2 + 8.5,
                            w: 516,
                            h: 19,
                            r: 3,
                            color: this.colorOrange,
                        },
                    ],
                    relativePosition: { x: 0, y: 0 },
                })

                content.push({
                    headlineLevel: 1,
                    table: {
                        widths: ["*"],
                        body: [
                            [
                                {
                                    text: category.main.toUpperCase(),
                                    style: "headingMain",
                                },
                            ],
                        ],
                    },
                    layout: this.layoutHeaderMain,
                })
            }



            for (const [i, sub] of category.sub.entries()) {

                // sub category
                content.push({
                    headlineLevel: 2,
                    table: {
                        widths: ["*"],
                        body: [
                            [
                                {
                                    text: sub.toUpperCase(),
                                    style: "headingSub",
                                },
                            ],
                        ],
                    },
                    layout: i == 0 ? this.layoutHeaderSub : this.layoutHeaderSubPadding,
                })

                // items
                const items = pricelistitems.filter((item) => {
                    return item.categoryMain == category.main && item.categorySub == sub
                })

                let rows = [
                    [
                        {
                            layout: this.layoutInnerTable,
                            headlineLevel: 3,
                            table: {
                                widths: pricelist ? this.tableWidthsPricelist : this.tableWidths,

                                body: [[
                                    { text: "Položka", style: "headingTable" },
                                    { text: "Jednotka", style: "headingTable" },
                                    { text: "Cena", style: "headingTable", alignment: "center" },
                                    { text: "Poznámka", style: "headingTable" },
                                ]]
                            }
                        }
                    ]
                ]

                if (pricelist) {
                    rows = [
                        [
                            {
                                layout: this.layoutInnerTable,
                                headlineLevel: 3,
                                table: {
                                    widths: pricelist ? this.tableWidthsPricelist : this.tableWidths,

                                    body: [[
                                        { text: "Položka", style: "headingTable" },
                                        { text: "Jednotka", style: "headingTable" },
                                        { text: "Cena", style: "headingTable", alignment: "center" },
                                        { text: "Počet", style: "headingTable", alignment: "center" },
                                        { text: "Celkem", style: "headingTable", alignment: "center" },
                                        { text: "Poznámka", style: "headingTable" },
                                    ]]
                                }
                            }
                        ]
                    ]
                }



                for (const item of items) {
                    if (pricelist) {
                        rows.push([
                            {
                                layout: this.layoutInnerTable,
                                headlineLevel: 3,
                                table: {
                                    headerRows: 0,
                                    widths: pricelist ? this.tableWidthsPricelist : this.tableWidths,
                                    body: [[
                                        { text: item.name, noWrap: true },
                                        { text: item.unit, noWrap: true },
                                        { text: this.numberWithSpaces(Math.round(item.price / rate * 10) / 10) + currencyText, alignment: "right", noWrap: true },
                                        { text: item.count, alignment: "center", noWrap: true },
                                        { text: this.numberWithSpaces(Math.round(item.price * item.count / rate * 10) / 10) + currencyText, alignment: "right", noWrap: true },
                                        { text: item.comment, noWrap: true },
                                    ]],
                                },
                            }]
                        )
                    } else {
                        rows.push([
                            {
                                layout: this.layoutInnerTable,
                                headlineLevel: 3,
                                table: {
                                    headerRows: 0,
                                    widths: pricelist ? this.tableWidthsPricelist : this.tableWidths,
                                    body: [
                                        [
                                            { text: item.name, noWrap: true, headlineLevel: 3 },
                                            { text: item.unit, noWrap: true },
                                            { text: this.numberWithSpaces(item.price) + " Kč", alignment: "right", noWrap: true },
                                            { text: item.comment, noWrap: true },
                                        ]
                                    ],
                                },
                            }]
                        )
                    }
                }

                // table
                content.push({
                    table: {
                        headerRows: 1,
                        widths: ["*"],

                        body: rows,
                    },
                    layout: this.layoutTable,
                })
            }
        }

        return content
    }

    createOverview(pricelist, currencyText) {
        return [
            {
                id: "overview",
                canvas: [
                    {
                        type: "rect",
                        x: 0,
                        y: this.paddingOverviewTop,
                        w: 516,
                        h: 138,
                        r: 3,
                        lineColor: this.colorOrange,
                    },
                ],
                relativePosition: { x: 0, y: 0 },
            },
            {
                id: "overview-table",
                layout: this.layoutOverview,
                table: {
                    widths: ["*", this.commentWidth - this.paddingOverview + 2],
                    body: [
                        [
                            {
                                layout: "noBorders",
                                table: {
                                    widths: ["*", "auto"],
                                    body: [
                                        [{ text: "Služby", headlineLevel: 3 }, { text: this.numberWithSpaces(pricelist.servicesPrice) + currencyText, alignment: "right" }],
                                        [{ text: "Technika", headlineLevel: 3 }, { text: this.numberWithSpaces(pricelist.gearPrice) + currencyText, alignment: "right" }],
                                        [
                                            { text: "Sleva na techniku", headlineLevel: 3 },
                                            { text: "- " + this.numberWithSpaces(Math.abs(pricelist.discount)) + currencyText, alignment: "right" },
                                        ],
                                        [
                                            { text: "Production fee", headlineLevel: 3 },
                                            { text: this.numberWithSpaces(pricelist.productionFee) + currencyText, alignment: "right" },
                                        ],
                                        [" ", " "],
                                        [
                                            { text: "Celková cena", bold: true, headlineLevel: 3 },
                                            { text: this.numberWithSpaces(pricelist.totalPrice) + currencyText, bold: true, alignment: "right" },
                                        ],
                                        [
                                            { text: "DPH " + pricelist.vat + "%", bold: true, headlineLevel: 3 },
                                            { text: this.numberWithSpaces(pricelist.vatPrice) + currencyText, bold: true, alignment: "right" },
                                        ],
                                        [
                                            { text: "Celková cena s DPH " + pricelist.vat + "%", bold: true, headlineLevel: 3 },
                                            { text: this.numberWithSpaces(pricelist.totalPriceWithVat) + currencyText, bold: true, alignment: "right" },
                                        ],
                                    ],
                                },
                            },
                            {
                                layout: "noBorders",
                                table: {
                                    widths: ["*", "auto"],
                                    body: [
                                        [
                                            { text: "Production fee", style: "rowFee" },
                                            { text: pricelist.productionFeePercent + "%", style: "rowFee", alignment: "right" },
                                        ],
                                        [
                                            { text: "Sleva na techniku", style: "rowFee" },
                                            { text: pricelist.discountPercent + "%", style: "rowFee", alignment: "right" },
                                        ],
                                    ],
                                },
                            },
                        ],
                    ],
                }
            }]
    }

    export(pricelistitems, pricelist, currency, rate) {
        if (pricelist) {
            pricelist.servicesPrice = Math.round(utils.pricelist.servicesPrice(pricelist) / rate * 10) / 10
            pricelist.gearPrice = Math.round(utils.pricelist.gearPrice(pricelist) / rate * 10) / 10
            pricelist.rawPrice = Math.round(utils.pricelist.rawPrice(pricelist) / rate * 10) / 10
            pricelist.discount = Math.round(utils.pricelist.discount(pricelist) / rate * 10) / 10
            pricelist.productionFee = Math.round(utils.pricelist.productionFee(pricelist) / rate * 10) / 10
            pricelist.totalPrice = Math.round(utils.pricelist.totalPrice(pricelist) / rate * 10) / 10
            pricelist.vatPrice = Math.round(utils.pricelist.vatPrice(pricelist) / rate * 10) / 10
            pricelist.totalPriceWithVat = Math.round(utils.pricelist.totalPriceWithVat(pricelist) / rate * 10) / 10
        }

        let currencyText = " Kč"
        if (currency == "eur") {
            currencyText = " €"
        }

        let content = []

        let title = pricelist ? `${pricelist.project.client.name} - ${pricelist.project.title} ${pricelist.project.subtitle}` : null

        content = content.concat(this.createHeader())
        content = content.concat(this.createTable(pricelistitems, pricelist, title, currencyText, rate))

        if (pricelist) {
            content = content.concat(this.createOverview(pricelist, currencyText))
        }

        const footer = (currentPage, pageCount) => {
            return {
                id: 'footer',
                headlineLevel: 100,
                layout: "noBorders",
                table: {
                    widths: [100, "*", 100],
                    body: [
                        [
                            " ",
                            {
                                text: "www.lifeREPORT.TV   |   production & broadcasting   |   2021",
                                alignment: "center",
                                style: "contact",
                            },
                            {
                                text: currentPage + "/" + pageCount,
                                alignment: "center",
                                // style: "contact",
                            },
                        ]
                    ],
                }
            }
        }

        const docDefinition = {
            pageSize: "A4",
            pageOrientation: "portrait",
            pageMargins: [this.margin, this.margin, this.margin, this.margin],
            content: content,
            footer: footer,
            images: this.images,
            defaultStyle: this.defaultStyle,
            styles: this.style,
            pageBreakBefore: (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) => {
                currentNode
                followingNodesOnPage
                nodesOnNextPage
                previousNodesOnPage

                if (currentNode.id == "overview-table") {
                    if (previousNodesOnPage[previousNodesOnPage.length - 1].id != "overview") {
                        return true
                    }
                }

                if (currentNode.headlineLevel == 1 || currentNode.headlineLevel == 2) {
                    if (followingNodesOnPage.filter((item) => item.headlineLevel).slice(0, 3).some((item) => item.id == "footer")) {
                        if (nodesOnNextPage.length > 0) {
                            return true
                        }
                    }
                }

                if (currentNode.headlineLevel == 3) {
                    if (followingNodesOnPage.filter((item) => item.headlineLevel).slice(0, 2).some((item) => item.id == "footer")) {
                        if (nodesOnNextPage.length > 0) {
                            return true
                        }
                    }
                }

                return false
            }
        };

        let date = moment().format("DDMMYYYY")
        let name = `Lifereport Cenik ${date}`

        if (pricelist) {
            name = `Rozpocet ${pricelist.project.client.name} - ${pricelist.project.title} ${pricelist.project.subtitle} ${date}`
        }

        pdfMake.createPdf(docDefinition).download(name.replace(/\./g, "_"))
        // name
        // pdfMake.createPdf(docDefinition).open()
    }

    numberWithSpaces(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    }
}